import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Prerequisites`}</h2>
    <ul>
      <li parentName="ul">{`A user with access to Engagement Studio;`}</li>
      <li parentName="ul">{`Already have created a Campaign and a widget.`}</li>
    </ul>
    <h2>{`Overview`}</h2>
    <p>{`The `}<strong parentName="p">{`Call Tracking`}</strong>{` allows us to track inbound calls with the same data we gather in Click to Call and to identify traffic origins and visitors.`}</p>
    <h2>{`Steps`}</h2>
    <h3>{`1. Add calltracker app`}</h3>
    <ol>
      <li parentName="ol">{`On the side bar of Engagement Studio, click `}<em parentName="li">{`AdCenter`}</em>{` (`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "67px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "104.4776119402985%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAABYlAAAWJQFJUiTwAAACc0lEQVQ4y41V2XaiQBDNB8w5zmgii4iC24hZVOK+4BKDsiXRZP7/T+6cKoGgiZqHC0139a2qvl3FlZjTcAqCXDwzV/x2z9WpjUmCG1FlCPJl51fnyCKUqwZKVQM/ySYmvBbyyGTz/E5ClIuwZgsGpXm8nskqHH1EyilnpQLuH0wsFitYsyWDxt3eCP3hBIPhBG2zh9HYQqc7xDxhN188oVa/Zw6OkNhLFQMvr2+8qdXuMtpml50MR1OsVjam1gLjiYVmq4OW2WXQuNcfwQ9eIef1fYQUtnHbxOrZRiotxmkQorS00l8U9Cr+XMvxfEb4tHFdn9cpSiasNx7wbK9xI+YhKXp80Dm1BFnR+YzUYhXGbQt6uR6nR6B11/OhapVPQiMkvBaU2JDmN47HKf/OSNg4Lm/0/YDTjISQctpXwnqCUAojonPdbndwXA+iXGDyUqWOXF5H8PLK62QnKz8gTGdzrJ7jeHjb7nD3YGL59IxytY5fqSzstQPjrsnX7CIhKVXQa9judphac9j2huF6AczHHvqDMTw/iFWVLhHSZKXWQPuxx6oKUgGd7gDDsYX1xsVsvkRRr8XCXDjDvcrkmRZpTKAxQ1TZZh+ZHqqs8bU5UrnJaZGaB2UlKBz1cbkdl53nBSho4T2kB334wQunM5nOMJnOw/cpzBlUOU8rG+u1w3V+UMt0JaicqMR2u3e02h2YnT7Mx0PQ2dKZfnz8Y4GoTNUwuoNuQxOpjIRytcFeo/TpahyChFM5KlI6fZOLW1ccYbInyvkSezzX++iqkE2yTON+uM9dO/AShX/cfI9tkt/7bh5G+N0/5GsnL551Eq3/B45MXNHI/hT6AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/381f8/ec-adCenter.webp 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
                "alt": "icon-adCenter",
                "title": "icon-adCenter",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`).`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d97bc1c5e7e45691e309288d886aeeda/d0f99/ec-menu.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAACe0lEQVQoz32QyWsTARTG5z8QVFwuKiq2dBFJBdGjqOCl3hREcVf8B8STUMW9HkTQghdXaOtS28nMtLbBYsFaWxubGmNrYpqETDJJprOkmWZp8pOmKnjxgx+893jv8fEJdTt2s+HiS7bdGKDpZh9bLktsbhGpvSJSd1Wk4Zqbhusi9ddFXLclXK0S2+9INLW62XVXYe+Dt+xr62fPPTc773QhrGvcxS3ZSyht8zVuEEhafP+DZhFM24xGMyj+GAFVxx/TCGqz9AbijM5oTMZ1ApqJTzU40T6EsGqLizdjQf6nChDVbaiUoVyASpGE7WBlc5QKhSrlUpEL0keENTVNdI5MVQ8LpQUWypV/WNRcvkh48SFQWhoR1i0iagJd19ENA9OyuCAPI6yucfHy03R1qbiwQPn3k6qzylLtFEtEZrOUi3mcuSyVYp64mcU0DBzLpJjLUcnPc2lgBGFtrYuWnhEmEyYjkTRedRbJH+V9UGU0msKr6nh+xOmbiqJZNtNahphhI09FaPs4waOxSR6P+3ni9XO2x4OwbH094pcQ0Xia+52DBEIqUTOH5cxjW1aVfKnE0E+N3m9J/vgfjiXY/7yb5nY3zR0iBzpEDr6SEFZu2srToUmeicMktRQPXwwyEUlhOw4z4TChUAgzm8UXzzAejP/NdTyhcei1zNFuhWM9CsfERWSE5Rsb6Rqbpve9j/4PX3n1doxwxmK+UEBPpchoGk6+QEw3sNPa33x9qTSHuyWOSwonFYVTvQqn+xSEFRsbafN4+RaM0OH5TCxt4tcMfDMqM2qSTHaORDbHeHSxT6BaNrM5h3fhCEd63JyUZc70yZzrlznvkfkF/n6e7vxCZiUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d97bc1c5e7e45691e309288d886aeeda/e93cc/ec-menu.webp 300w", "/static/d97bc1c5e7e45691e309288d886aeeda/b0544/ec-menu.webp 600w", "/static/d97bc1c5e7e45691e309288d886aeeda/68fc1/ec-menu.webp 1200w", "/static/d97bc1c5e7e45691e309288d886aeeda/a2303/ec-menu.webp 1800w", "/static/d97bc1c5e7e45691e309288d886aeeda/4293a/ec-menu.webp 2400w", "/static/d97bc1c5e7e45691e309288d886aeeda/81194/ec-menu.webp 3014w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d97bc1c5e7e45691e309288d886aeeda/eed55/ec-menu.png 300w", "/static/d97bc1c5e7e45691e309288d886aeeda/7491f/ec-menu.png 600w", "/static/d97bc1c5e7e45691e309288d886aeeda/8537d/ec-menu.png 1200w", "/static/d97bc1c5e7e45691e309288d886aeeda/d2cc9/ec-menu.png 1800w", "/static/d97bc1c5e7e45691e309288d886aeeda/00711/ec-menu.png 2400w", "/static/d97bc1c5e7e45691e309288d886aeeda/d0f99/ec-menu.png 3014w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d97bc1c5e7e45691e309288d886aeeda/8537d/ec-menu.png",
              "alt": "ec-menu",
              "title": "ec-menu",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click `}<em parentName="li">{`Apps`}</em>{` on the menu.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "452px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fb5b8df4c898d964eb3a778455287aa5/1a6c0/ccas-app-list.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "174.00000000000003%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAjCAYAAACU9ioYAAAACXBIWXMAABYlAAAWJQFJUiTwAAADXUlEQVRIx6VWW08TQRSebalovMQLykVjxEQj3mMkMfhgNKLEBy/xJ4htt1suCgIFAgpCRElE4AHiM7TQ7RZouZablKD/6jNzZqdsS0tbffgys7OzZ875znfOLCvzRVDSFkGJ7z9A30dR2mKAMU8IGaFZ4MkGA8zpB7N5DSheA0zVE7DVmc/uoIB8x/elRQjMG4aiBsDoY7eOC11LuNKzgsqv67jRt4qL3Uuo6F3Brf5VlHctobx7SXzMvU01qAmDzB0As5PBIO4OrOP52A5e//xDRl+O7+DF2A5qRrfxavw37n3bAPNk8NJqUJGLnAdvCAcawnA0hEXYJn92y5zC09KA20gY1ORJRspG65qxD4eG1UO+WYeihcDn6ZDWoyTodKDi9oMpWhDMw7M5DeacAnNNiVHCNS3eZwN3yjVhZtkTwrmOBVz/HMOlj8uU7YreGI3nOxfTZzZTyNJgafs8SeTyp2XKaNXgBo1cMlkNJmVZuixDlaHL8F1T4LTwfQQtU9hmyIp5QkF9WJwkq0LO3RLBXXj0DLLxS2HrxN/DoS3Cg++/cH9wE9d6Y3g0tIXKgXVUD8fxZDhOY6lv3tRtmpBt5sLh97Moao0STrVEcbIlQjjTxp8jONuxQAnixg69m0nmNSOHbwNgtQExStT6E1wmeFVTpTS9y6HNLDvuBT+9rH0ep1ujNC/xzZNnBxuFR4q1u2QqPVtdGEwNkTyqBjeJO87jnS9ruNm3isc/4ihO5Wzf0uNlw7OmmtVCsMx5paiiEmhfWgRFBK5J06AmDZowjShU43oWY3pCRmSQQnbrJBHeE2/3r1HoV3tjOZZcaulRUnSSCK/n4rYoKnpWUNIWzaHLJCdFdBuZeqcpGz6+8YtRDebWaWTpOSd2hX2kaQ7HmudIIlw2hY0zsHM6cgl3752iU6d5NrpNkqkZEePTkTgZ5zWt5NxtLCItqDcIfI0f5KgPQ0aQM4c2Kw+SR9XUnjqdH4dJHbtzkcLmXdpBrczIQzKhlI6thXC0aY46NIdD9kavkb8OE7KxXlJ5yWVPxxakin+cXK/NfZJiN0uPl1z1SJzkcuJDJIfukiZk+lniHnl0FDaEUdQSwfHmWdjr5OWdD3hz8Mv2FUxuX55/4JAu+knLz9J/Q2T5L0p32Qu5Hv0kAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fb5b8df4c898d964eb3a778455287aa5/e93cc/ccas-app-list.webp 300w", "/static/fb5b8df4c898d964eb3a778455287aa5/7acc5/ccas-app-list.webp 452w"],
              "sizes": "(max-width: 452px) 100vw, 452px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fb5b8df4c898d964eb3a778455287aa5/eed55/ccas-app-list.png 300w", "/static/fb5b8df4c898d964eb3a778455287aa5/1a6c0/ccas-app-list.png 452w"],
              "sizes": "(max-width: 452px) 100vw, 452px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/fb5b8df4c898d964eb3a778455287aa5/1a6c0/ccas-app-list.png",
              "alt": "ccas-app-list",
              "title": "ccas-app-list",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Click `}<em parentName="li">{`Add`}</em>{` to insert an app.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/734aa2beac7394cf36aef109d504d5cb/1458a/ec-insert-app.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAABIklEQVQY053MXStDcQDH8fM2lGXJw4TYlUiNlAuXLryY1STzsKREeRnK/xw7m6WUF0CbPRWTOGuzsXPO/xzbivpyNrkT+dWnfldfZWJhmb6wYDiaxB/R6Akf44sI/KuC/jXBwLpgKCoIbAhGtlRGt1XGYirjMZXJHY2pPZ2Z/QRzBzorh0covcF5NuNpUoUKasZAZAy0awMta3CSNYjnuvR8mYSn0JXsMEjkHkgVy1yUquyeXXnBEOd5g//OtS14f+v8VPERJTC7RPyyhG1LnhsmLx7zbxqWRblSpVqrY5k2auYGZXB6kdP0Ha50kLaNI+Wv5Bfv12s1TMtE2hI9d4viC4bQ0/c4zTam28Rutr7Jn7S6nFabhuNiua88mQ7iM/gBYoGZX6MdE/YAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/734aa2beac7394cf36aef109d504d5cb/e93cc/ec-insert-app.webp 300w", "/static/734aa2beac7394cf36aef109d504d5cb/b0544/ec-insert-app.webp 600w", "/static/734aa2beac7394cf36aef109d504d5cb/68fc1/ec-insert-app.webp 1200w", "/static/734aa2beac7394cf36aef109d504d5cb/a2303/ec-insert-app.webp 1800w", "/static/734aa2beac7394cf36aef109d504d5cb/4293a/ec-insert-app.webp 2400w", "/static/734aa2beac7394cf36aef109d504d5cb/d1b65/ec-insert-app.webp 2968w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/734aa2beac7394cf36aef109d504d5cb/eed55/ec-insert-app.png 300w", "/static/734aa2beac7394cf36aef109d504d5cb/7491f/ec-insert-app.png 600w", "/static/734aa2beac7394cf36aef109d504d5cb/8537d/ec-insert-app.png 1200w", "/static/734aa2beac7394cf36aef109d504d5cb/d2cc9/ec-insert-app.png 1800w", "/static/734aa2beac7394cf36aef109d504d5cb/00711/ec-insert-app.png 2400w", "/static/734aa2beac7394cf36aef109d504d5cb/1458a/ec-insert-app.png 2968w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/734aa2beac7394cf36aef109d504d5cb/8537d/ec-insert-app.png",
              "alt": "ec-insert-app",
              "title": "ec-insert-app",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Select `}<em parentName="li">{`App - CallTracker`}</em>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c9a0fbe7570323974c3203d2c5c20ef4/a486b/ec-calltracker-selected.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAACIklEQVQoz42QS08TYRSG56cY8RKD0AL2Ri9mKKX3+b6ZwQpqL1BCw8KNyBKNggtggcS44pb4p0w0sU2MCcZAKbSETmkeMzNEt5zkyclZnCfnvIpnUseT0BmcECysrPJ6bZNX7zZYfr/B8tomy2sb/1l355UPW7x8s85o1sST0vGmDfwZyVhaR8mYs6TMWRKyQL3R4KbVbrcxXlRImk/JFJ6RL8wQzBooiwvzLFTnqc5V+FFv0LV6dLtd+v0+v4/POGl16PevuLpy6fV6jrDZbLJUq1EulZz9xWqVuFFAmavMUS5XKJZK1OsNLi8v6XQ6jvC83aF1du5I7NnGltp10myyWKtRLJYolsuUSmXixhMUf9YgmDPxZ3S+fvvOxcWF844tOW2d0mq1sCwLy+o5vWtZrvDkmOTMcyKaQUxOE5MmgbyO4ssYPJwSPIjn+HV0dKP87Bt//jlDLcwyks7hywl8WYE/J1BUWcCbkgxNaaxubfPp8Avbuwd83D1gZ++Qnf1DdvYO/rG9u8/K5meW3m4RM6YJ5AQhTToENYkymNAYTgo8KclAdJJbIZXbYZeBiModm6jK3ajKvZjK/ccTDE3E8U4mCOYFISEZt5EuiicpHZk3JRjLGvjypoNfMwloJkFhEhIm49IkLE0iuklYd/u41AlLSVjXHSK6jjLsCIUjHEm7jGYEYxnBo6xw8rGzCeQFwbz7Wuj6orC0JdIharj8BeGd/2HVP15eAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/c9a0fbe7570323974c3203d2c5c20ef4/e93cc/ec-calltracker-selected.webp 300w", "/static/c9a0fbe7570323974c3203d2c5c20ef4/b0544/ec-calltracker-selected.webp 600w", "/static/c9a0fbe7570323974c3203d2c5c20ef4/68fc1/ec-calltracker-selected.webp 1200w", "/static/c9a0fbe7570323974c3203d2c5c20ef4/2d2f1/ec-calltracker-selected.webp 1419w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/c9a0fbe7570323974c3203d2c5c20ef4/eed55/ec-calltracker-selected.png 300w", "/static/c9a0fbe7570323974c3203d2c5c20ef4/7491f/ec-calltracker-selected.png 600w", "/static/c9a0fbe7570323974c3203d2c5c20ef4/8537d/ec-calltracker-selected.png 1200w", "/static/c9a0fbe7570323974c3203d2c5c20ef4/a486b/ec-calltracker-selected.png 1419w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c9a0fbe7570323974c3203d2c5c20ef4/8537d/ec-calltracker-selected.png",
              "alt": "ec-calltracker-selected",
              "title": "ec-calltracker-selected",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Fill in the `}<em parentName="li">{`Name`}</em>{`, select `}<em parentName="li">{`Yes`}</em>{` to activate, select a `}<em parentName="li">{`widget to use`}</em>{` and `}<em parentName="li">{`Campaign to use`}</em>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "894px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/06bab1546d93c66616d6c2fc182bbdc2/07707/ec-create-app-calltracker.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRklEQVQoz6WRPU/CUBSG72/yD7g46YhGHVhUCgOLq4sbA4kDRLFFJRpLCxqjojLwF1xNXE1sdWjTtNDQ777m9MOvsHGSJzf33HPe+5572VplF/37IS6ubyHfPaI/GKL3D8r1H2bTGzzhZjjC4bmIheUCWL3ZQhJRhHlC0zSsbBbBBEFIEmEYIQzDhCAIYI3HcBwXjuPAdV34QQDf9/9AdQSFoiiolDkwnuczgxHiOP6+kUQ8z0ugZgo6z8nro2wyVVWxUyqBCTMEScy2bViWhclkkrjIhWmlPZ2T+yjrURQVFY4Daxylb0hF+ciGYcA0TZiWBV3XU7eZWC5MgtPplHwn/Z8fKopb22D7B825PuPd8DB6MfD8+oal9Q2w1XIVHfkKwqWEtijjhOj+cPobKeVMktEWJQhiF7VWB9XaMfbqDSwWCvgCtlg3E0tXfFAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/06bab1546d93c66616d6c2fc182bbdc2/e93cc/ec-create-app-calltracker.webp 300w", "/static/06bab1546d93c66616d6c2fc182bbdc2/b0544/ec-create-app-calltracker.webp 600w", "/static/06bab1546d93c66616d6c2fc182bbdc2/cf8ca/ec-create-app-calltracker.webp 894w"],
              "sizes": "(max-width: 894px) 100vw, 894px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/06bab1546d93c66616d6c2fc182bbdc2/eed55/ec-create-app-calltracker.png 300w", "/static/06bab1546d93c66616d6c2fc182bbdc2/7491f/ec-create-app-calltracker.png 600w", "/static/06bab1546d93c66616d6c2fc182bbdc2/07707/ec-create-app-calltracker.png 894w"],
              "sizes": "(max-width: 894px) 100vw, 894px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/06bab1546d93c66616d6c2fc182bbdc2/07707/ec-create-app-calltracker.png",
              "alt": "ec-create-app-calltracker",
              "title": "ec-create-app-calltracker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Name`}</em>{`: It is required  and it is free text but you should give a meaningful name.`}</li>
      <li parentName="ul"><em parentName="li">{`Active`}</em>{`: `}<em parentName="li">{`Yes`}</em>{` or `}<em parentName="li">{`No`}</em></li>
      <li parentName="ul"><em parentName="li">{`Channel`}</em>{`: Select `}<em parentName="li">{`Default`}</em>{` or use an existing one.`}</li>
      <li parentName="ul"><em parentName="li">{`Widget`}</em>{`: Select a widget from the list of widgets.`}</li>
      <li parentName="ul"><em parentName="li">{`Campaign to use`}</em>{`: Select a campaign from the list of campaigns.`}</li>
    </ul>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`On the right bottom corner of form `}<em parentName="li">{`Create App`}</em>{`, click button `}<em parentName="li">{`Accept`}</em>{`. `}</li>
      <li parentName="ol">{`The edit app is displayed to configure additional calltracker settings.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7bd432c8c0e9bdaf8596937541ca81a4/4f479/ec-edit-calltracker.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB0UlEQVQoz5WSW2sTQRiG9x9JEQRBEAqC4IWgZNOKSgX9I+KdiLUe2rRJm6QeQjGZWEy80EB3sRK1aWsKtsmmNSiaoJcJSfYw+8hutkZorPSDlzl988z7zYwyenOZ6/NFxmd0Lj5cITS9QiiiEZrVUKM6akxDndcIL2iE47qvsYTOWFInnNS5lNSYSK1yeeENaiSHci9XYni44Er+G65ESocvjRaLH3ZRpl6t+/Om7eC6biAwLYuuaSKlt8HFCdpBziDXka7PeFtvotzNl4YAXUzT9GXbNpZlDQEN5B3mhV5voDx4veEPbEf6i37ftmm32746nQ69Xq9f3T+B/X2rX5sDoPUX0HPUarXodrs+zBsfDuw7fPfNAxY2Dzh0HMcHeu72yz6sZBk4LH5votwvbPy5Q0e6gWT/EbzkQMPm9mUHDt//aKDMaeWDX0HaYJn9r3OEKP/8hXI1USBe2mO6WGH2Y5XomqcKc2vbxNZ3SHyqkixXebxV5dlng9S2wdKOwfOKQcaoIWo1srsG+foeM1ubKCO3nnLsdoaRO2mOT6Y5MZXm5KMMpyKC01HBaExwJi44uyg490RwPiW4sCRQ01nGRZYry1kmci+49lJwIy/4DYNaGwrlqDK9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7bd432c8c0e9bdaf8596937541ca81a4/e93cc/ec-edit-calltracker.webp 300w", "/static/7bd432c8c0e9bdaf8596937541ca81a4/b0544/ec-edit-calltracker.webp 600w", "/static/7bd432c8c0e9bdaf8596937541ca81a4/68fc1/ec-edit-calltracker.webp 1200w", "/static/7bd432c8c0e9bdaf8596937541ca81a4/fdd64/ec-edit-calltracker.webp 1649w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7bd432c8c0e9bdaf8596937541ca81a4/eed55/ec-edit-calltracker.png 300w", "/static/7bd432c8c0e9bdaf8596937541ca81a4/7491f/ec-edit-calltracker.png 600w", "/static/7bd432c8c0e9bdaf8596937541ca81a4/8537d/ec-edit-calltracker.png 1200w", "/static/7bd432c8c0e9bdaf8596937541ca81a4/4f479/ec-edit-calltracker.png 1649w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7bd432c8c0e9bdaf8596937541ca81a4/8537d/ec-edit-calltracker.png",
              "alt": "ec-edit-calltracker",
              "title": "ec-edit-calltracker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`It is possible to create a widget or a campaign from here.`}</p>
    </blockquote>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`On the Edit app, define the configurations.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7bd432c8c0e9bdaf8596937541ca81a4/4f479/ec-edit-calltracker.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB0UlEQVQoz5WSW2sTQRiG9x9JEQRBEAqC4IWgZNOKSgX9I+KdiLUe2rRJm6QeQjGZWEy80EB3sRK1aWsKtsmmNSiaoJcJSfYw+8hutkZorPSDlzl988z7zYwyenOZ6/NFxmd0Lj5cITS9QiiiEZrVUKM6akxDndcIL2iE47qvsYTOWFInnNS5lNSYSK1yeeENaiSHci9XYni44Er+G65ESocvjRaLH3ZRpl6t+/Om7eC6biAwLYuuaSKlt8HFCdpBziDXka7PeFtvotzNl4YAXUzT9GXbNpZlDQEN5B3mhV5voDx4veEPbEf6i37ftmm32746nQ69Xq9f3T+B/X2rX5sDoPUX0HPUarXodrs+zBsfDuw7fPfNAxY2Dzh0HMcHeu72yz6sZBk4LH5votwvbPy5Q0e6gWT/EbzkQMPm9mUHDt//aKDMaeWDX0HaYJn9r3OEKP/8hXI1USBe2mO6WGH2Y5XomqcKc2vbxNZ3SHyqkixXebxV5dlng9S2wdKOwfOKQcaoIWo1srsG+foeM1ubKCO3nnLsdoaRO2mOT6Y5MZXm5KMMpyKC01HBaExwJi44uyg490RwPiW4sCRQ01nGRZYry1kmci+49lJwIy/4DYNaGwrlqDK9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7bd432c8c0e9bdaf8596937541ca81a4/e93cc/ec-edit-calltracker.webp 300w", "/static/7bd432c8c0e9bdaf8596937541ca81a4/b0544/ec-edit-calltracker.webp 600w", "/static/7bd432c8c0e9bdaf8596937541ca81a4/68fc1/ec-edit-calltracker.webp 1200w", "/static/7bd432c8c0e9bdaf8596937541ca81a4/fdd64/ec-edit-calltracker.webp 1649w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7bd432c8c0e9bdaf8596937541ca81a4/eed55/ec-edit-calltracker.png 300w", "/static/7bd432c8c0e9bdaf8596937541ca81a4/7491f/ec-edit-calltracker.png 600w", "/static/7bd432c8c0e9bdaf8596937541ca81a4/8537d/ec-edit-calltracker.png 1200w", "/static/7bd432c8c0e9bdaf8596937541ca81a4/4f479/ec-edit-calltracker.png 1649w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7bd432c8c0e9bdaf8596937541ca81a4/8537d/ec-edit-calltracker.png",
              "alt": "ec-edit-calltracker",
              "title": "ec-edit-calltracker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Pool type`}</em>{`: `}<em parentName="li">{`Normal`}</em>{` or `}<em parentName="li">{`Dynamic`}</em>{`. Normal is a pool managed by Coremedia and the Dynamic is a pool managed by the client. `}</li>
      <li parentName="ul"><em parentName="li">{`Pool`}</em>{`: `}<em parentName="li">{`No Pool`}</em>{`. To make a pool available, should be requested to the CMEC Support Team`}</li>
      <li parentName="ul"><em parentName="li">{`Pool exhausted`}</em>{`: `}<em parentName="li">{`Do not show element/widget`}</em>{` or `}<em parentName="li">{`Number default`}</em>{`. Here we can define what happens when the pool is sold out: you can hide it or show a default number.  `}</li>
      <li parentName="ul"><em parentName="li">{`Expiration allocation`}</em>{`: `}<em parentName="li">{`No expire`}</em>{` or `}<em parentName="li">{`Expire`}</em>{`.`}</li>
      <li parentName="ul"><em parentName="li">{`Number default`}</em>{`: Set the default number. Here we can define how much time it takes for the number allocation to expire.`}</li>
      <li parentName="ul"><em parentName="li">{`Target skill`}</em>{`: Select a destination skill.`}</li>
      <li parentName="ul"><em parentName="li">{`Conversion Tag`}</em>{`: `}<em parentName="li">{`Conversion Pixel`}</em>{` or `}<em parentName="li">{`Conversion Url`}</em>{`. Select if you will have a conversion pixel or url, and it’s content. This pixel is sent went the atributed pool number’s visitor makes a conversion.`}</li>
      <li parentName="ul"><em parentName="li">{`Conversion Pixel`}</em>{`: Input content .`}</li>
      <li parentName="ul"><em parentName="li">{`Tag in the number by default`}</em>{`: Tickle if needed.`}</li>
      <li parentName="ul"><em parentName="li">{`Recycling method`}</em>{`: Select the desire reallocation method. There are 2 options: `}<em parentName="li">{`Active socket`}</em>{` or `}<em parentName="li">{`Home+Timeout`}</em>{`.`}</li>
    </ul>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Change `}<em parentName="li">{`Status`}</em>{` to `}<em parentName="li">{`On`}</em>{`. `}</li>
      <li parentName="ol">{`On the right bottom corner of form `}<em parentName="li">{`Edit App`}</em>{`, click button `}<em parentName="li">{`Save`}</em>{`. `}</li>
    </ol>
    <h4>{`Result`}</h4>
    <p>{`After configuring the app, it can be used in an `}<strong parentName="p">{`onsite campaign`}</strong>{`, by creating a widget type `}<em parentName="p">{`Calltracker`}</em>{` and selecting the app created. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/90bfa930dc6010ed222c599ff4ef0d92/96f55/ec-campaign-calltracker.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABdklEQVQoz4XP0U7UQBTG8T6Sd2qC3hEQXbvbnXamM93ibsQb+gxAeRQTo0a3RAGB+AyGB+DGSxPBRCLblm77N9NuSIwhnOSXc2aS+XLGebDscu/xE9xog7fTT7z5sMe77DPZwTHZ4QnZwRHT/SOyhen+F/YOT3j9/iOP1jzuL/d4uOqytNpjaeUZjgoN7sAj3d3lv6orbqt5VRGvv0AEkp4nWev7rDwf4sSjEYHvk+7sUM3nzBd+X17y4+cvrvKCWXFNXloVRXlN3TScn18wmUzQWmNM1PIC1QUK4ZOm6T8bFEVJXpQ0TU3TNDf3TV23vZz9YTzuAqMoYjSKGEqFo02EEIKtrW2KoqAsy5ue53nLni07X81mfPt+wdfTM169nKDCsAuMFoEq1GhtiOOYJEnutJkkmPEGar3bLgxDjDFtqBdIHKN1mx4ZQ6jUnZSUaCUxocSY7rv2rSXshq6QWH0rUAxu4Um50M0DKwgYBF3v+wFPh4K/cLiuQdM7F6cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/90bfa930dc6010ed222c599ff4ef0d92/e93cc/ec-campaign-calltracker.webp 300w", "/static/90bfa930dc6010ed222c599ff4ef0d92/b0544/ec-campaign-calltracker.webp 600w", "/static/90bfa930dc6010ed222c599ff4ef0d92/68fc1/ec-campaign-calltracker.webp 1200w", "/static/90bfa930dc6010ed222c599ff4ef0d92/a2303/ec-campaign-calltracker.webp 1800w", "/static/90bfa930dc6010ed222c599ff4ef0d92/4293a/ec-campaign-calltracker.webp 2400w", "/static/90bfa930dc6010ed222c599ff4ef0d92/50a30/ec-campaign-calltracker.webp 2790w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/90bfa930dc6010ed222c599ff4ef0d92/eed55/ec-campaign-calltracker.png 300w", "/static/90bfa930dc6010ed222c599ff4ef0d92/7491f/ec-campaign-calltracker.png 600w", "/static/90bfa930dc6010ed222c599ff4ef0d92/8537d/ec-campaign-calltracker.png 1200w", "/static/90bfa930dc6010ed222c599ff4ef0d92/d2cc9/ec-campaign-calltracker.png 1800w", "/static/90bfa930dc6010ed222c599ff4ef0d92/00711/ec-campaign-calltracker.png 2400w", "/static/90bfa930dc6010ed222c599ff4ef0d92/96f55/ec-campaign-calltracker.png 2790w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/90bfa930dc6010ed222c599ff4ef0d92/8537d/ec-campaign-calltracker.png",
              "alt": "ec-campaign-calltracker",
              "title": "ec-campaign-calltracker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`Note: It is necessary to configure the widget and the placeholder, as already explain in the course `}<strong parentName="p">{`Campaigns`}</strong>{`. For additional information check the course `}<strong parentName="p">{`Campaigns`}</strong>{` > `}<strong parentName="p">{`Configure smart windows`}</strong>{`. `}</p>
    </blockquote>
    <p>{`Finally, in order to present the calltracker numbers, for instance, on the clients website header, it’s necessary to created the code that will replace the contents with the selected number from the pool. For this part of the implementation it is necessary to ask for CMEC Support Team help. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      